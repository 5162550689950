/* 
html {
  scrollbar-width: thin; 
  scrollbar-color: #007bff #e6f3ff; 
}


::-webkit-scrollbar {
  width: 3px; 
  height: 3px; 
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #007bff, #0056b3); 
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #0056b3, #003d7a); 
}

::-webkit-scrollbar-track {
  background-color: transparent; 
  border-radius: 10px; 
  margin: 1px; 
} */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */

::-moz-scrollbar {
  display: none;
}

/* Hide scrollbar for Internet Explorer and Edge */

::-ms-scrollbar {
  display: none;
}

/* Always visible scrollbar for the specific class */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-1cjsu7f-MuiPaper-root-MuiDrawer-paper {
  overflow-y: scroll; /* Always show vertical scrollbar */
  scrollbar-width: thin; /* Firefox: Thin scrollbar */
  scrollbar-color: #007bff transparent; /* Blue thumb, no track */
}

/* WebKit browsers (Chrome, Edge, Safari) */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-1cjsu7f-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar {
  max-width: 2px !important; /* Adjust width of the scrollbar */
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-1cjsu7f-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar-thumb {
  background-color: #007bff; /* Blue thumb color */
  border-radius: 5px !important; /* Rounded corners for thumb */
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-1cjsu7f-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar-thumb:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-1cjsu7f-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar-track {
  background: transparent; /* No visible track */
}

